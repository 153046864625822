/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*more*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    pre: "pre",
    code: "code",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Today Quill is ready for its first beta preview of 1.0. This is the biggest rewrite to Quill since its inception and enables many new possibilities not available in previous versions of Quill, nor any other editor. The code is as always available on GitHub and through npm:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "npm install quill@1.0.0-beta.0\n")), "\n", React.createElement(_components.p, null, "The skeleton of a new documentation site is also being built out at ", React.createElement(_components.a, {
    href: "https://beta.quilljs.com"
  }, "beta.quilljs.com"), ". Whereas the current site focuses on being a referential resource, the new site will also be a guide to provide insight on approaching different customization goals. There is also an ", React.createElement(_components.a, {
    href: "https://beta.quilljs.com/playground/"
  }, "interactive playground"), " to try out various configurations and explore the API."), "\n", "\n", React.createElement(_components.p, null, "The goal now is of course an official 1.0 release. To get there, Quill will now enter a weekly cadence of beta releases, so you can expect rapid interations on stability and bug fixes each week. GitHub is still the center of all development so please do report ", React.createElement(_components.a, {
    href: "https://github.com/quilljs/quill/issues"
  }, "Issues"), " as you encounter them in the beta preview."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
